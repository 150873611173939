<template>
  <div id="borrow">
    <van-nav-bar
      title="图书借阅"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="logo">
      <img src="../assets/image/d.png" alt="" />
      <!-- <div class="title">图书借阅</div> -->
    </div>
    <div>
      <van-search
        v-model="barcode"
        show-action
        shape="round"
        type="number"
        placeholder="请输入条码号"
        @search="borrow"
      >
        <template #action>
          <div @click="opencreame" style="font-size: 26px">
            <van-icon name="scan" />
          </div>
        </template>
      </van-search>
    </div>
    <van-button
      type="info"
      style="width: 80%; margin-top: 120px"
      @click="borrow"
      >借阅</van-button
    >
  </div>
</template>
<script>
import { borrow } from "@/api/goods.js";
export default {
  data() {
    return {
      barcode: "",
    };
  },
  created() {
    //  this.wxconfigInit()
  },
  methods: {
    opencreame() {
      this.$wx.ready(() => {
        this.$wx.checkJsApi({
          jsApiList: ["scanQRCode"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
          success: (res) => {
            this.$wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: (res) => {
                this.barcode = res.resultStr.split(",")[1];
                // this.decodeInfo = JSON.parse(this.decode(this.qrcodeInfo));
              },
              fail: (err) => {
                alert(err.errMsg);
                // this.signIn();
              },
            });
          },
        });
      });

      // this.$wx.scanQRCode({
      //   needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      //   scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      //   success: function (res) {
      //     setTimeout(() => {
      //       var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
      //       console.log("扫码返回的结果", result);
      //       resolve({ result });
      //     }, 1000);
      //   },
      //   error: function (res) {
      //     console.log(res);
      //     console.log("取消扫码");
      //     // Toast.fail('取消扫码');
      //     resolve({});
      //   },
      // });
    },
    borrow() {
      if (!this.barcode) {
        this.$notify({
          message: "请输入条码号",
          duration: 2000,
          background: "#f44",
        });
      } else {
        this.$dialog
          .confirm({
            message: "是否借阅当前图书？",
          })
          .then(() => {
            borrow({
              // stunum:localStorage.getItem('stunum'),
              // name:localStorage.getItem('name'),
              // password:localStorage.getItem('password'),
              // uuid:localStorage.getItem('uuid'),
              barcode: this.barcode,
            }).then((res) => {
              console.log(res);
              this.$toast({
                message: res.data.code == 200 ? "借阅成功" : res.data.msg,
                type: res.data.code == 200 ? "success" : "fail",
              });
            });
          })
          .catch(() => {
            // on cancel
          });

        //  this.$wx.getLocation({
        //     type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
        //     success: function (res) {
        //       var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        //       var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        //       var speed = res.speed; // 速度，以米/每秒计
        //       var accuracy = res.accuracy; // 位置精度
        //     }
        //   });
      }
    },
    wxconfigInit() {
      const url = window.location.href.split("#")[0]; // 动态获取当前页的#之前的url地址，这个是有https:// 或者http://的

      // 调用后端接口返回，配置需要的结果

      return room
        .getJSSDKConfig({ apis: "scanQRCode", url: url, debug: 1 })

        .then((res) => {
          const data = JSON.parse(res.data[0]);

          this.$wx.config({
            debug: false, // 开启调试模式,

            appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid

            timestamp: data.timestamp, // 必填，生成签名的时间戳

            nonceStr: data.nonceStr, // 必填，生成签名的随机串

            signature: data.signature, // 必填，签名，见附录1

            jsApiList: ["checkJsApi", "scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
        })
        .catch((err) => {
          console.log(err.msg);
        });
    },
    isSearchClick() {
      let _this = this;
      this.$wx.checkJsApi({
        jsApiList: ["scanQRCode"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
        success: function (res) {
          _this.$wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              var result = res.resultStr;
              if (result.includes("CODE_128")) {
                result = result.split(",")[1];
              }
              _this.barcode = result;
            },
            fail: function () {},
          });
        },
      });
    },
    onRead(file) {
      console.log(file);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push({
        name: "Index",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#borrow {
  .logo {
    margin-top: 60px;
    width: 100%;
    height: 200px;
    .title {
      text-align: center;
      width: 100%;
      font-size: 20px;
    }
  }
}
</style>